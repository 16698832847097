import api from '../axios/api';

const BASE_URL = 'PII';
export async function getCellPhone(orderId: string): Promise<string> {
  const res = await api.get<string>(`${BASE_URL}/GetPhone/${orderId}`);
  return res.data;
}

export async function getCellPhoneByPDKUUID(pdkuuid: string): Promise<string> {
  const res = await api.get<string>(
    `${BASE_URL}/GetCellphoneByPDKUUID/${pdkuuid}`
  );
  return res.data;
}
