import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import React from 'react';
import { ReactComponent as RemainderIcon } from '../../assets/svgs/remainder.svg';
import { ReactComponent as PaymentIcon } from '../../assets/svgs/payment.svg';
import { ReactComponent as ContactUsIcon } from '../../assets/svgs/contactUs.svg';
import { ReactComponent as OrderIcon } from '../../assets/svgs/orders.svg';
import { ReactComponent as KeyIcon } from '../../assets/svgs/key.svg';
import { ReactComponent as ErrorIcon } from '../../assets/svgs/error.svg';
import { ReactComponent as ViewOrder } from '../../assets/svgs/viewOrder.svg';
import { ReactComponent as ListIcon } from '../../assets/svgs/list.svg';
import { ReactComponent as ApproveIcon } from '../../assets/svgs/approve.svg';
import { ReactComponent as CookiesIcon } from '../../assets/svgs/cookies.svg';
import { ReactComponent as PriceListIcon } from '../../assets/svgs/priceList.svg';

import colors from '../../assets/colors';
import useBreakpoint from '../../hooks/useBreakpoint';

interface TitleProps {
  content: string;
  icon?:
    | 'remainder'
    | 'payment'
    | 'contactUs'
    | 'order'
    | 'key'
    | 'error'
    | 'list'
    | 'terms'
    | 'approve'
    | 'cookies'
    | 'priceList';
  sx?: SxProps<Theme> | undefined;
}

const StyledTitle = styled(Typography)(() => ({
  textAlign: 'center',
  margin: '4% 0',
  fontWeight: 700,
  paddingTop: '4rem',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

export const StyledFormDescription = styled(Typography)(() => ({
  margin: '4% 0',
  fontWeight: 400,
  fontSize: '20px',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

const Title: React.FC<TitleProps> = ({ content, icon, sx }) => {
  const { isMobile } = useBreakpoint();
  const iconSize = isMobile ? 40 : 80;
  const style: SxProps<Theme> | undefined = isMobile
    ? {
        fontSize: '2rem',
        textAlign: 'start',
      }
    : {
        fontSize: '2.9rem',
        maxWidth: '25rem',
        margin: '4% 0',
        textAlign: 'start',
        lineHeight: '3.8rem',
      };
  const titleStyle = sx !== undefined ? sx : style;
  return (
    <Box
      style={
        isMobile
          ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }
          : {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20px',
              marginLeft: '-2px',
              marginBottom: '4%',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            }
      }
    >
      <StyledTitle variant="h3" sx={titleStyle}>
        {content}
      </StyledTitle>
      <Box sx={{ marginTop: '3rem' }}>
        {icon === 'remainder' && (
          <RemainderIcon
            fill={colors.blue}
            width={iconSize}
            height={iconSize}
          />
        )}
        {icon === 'payment' && (
          <PaymentIcon fill={colors.blue} width={iconSize} height={iconSize} />
        )}
        {icon === 'contactUs' && (
          <ContactUsIcon
            fill={colors.blue}
            width={iconSize}
            height={iconSize}
          />
        )}
        {icon === 'order' && (
          <OrderIcon fill={colors.blue} width={iconSize} height={iconSize} />
        )}
        {icon === 'key' && (
          <KeyIcon fill={colors.blue} width={iconSize} height={iconSize} />
        )}
        {icon === 'error' && (
          <ErrorIcon fill={colors.red} width={iconSize} height={iconSize} />
        )}
        {icon === 'list' && (
          <ViewOrder fill={colors.blue} width={iconSize} height={iconSize} />
        )}
        {icon === 'terms' && (
          <ListIcon fill={colors.blue} width={iconSize} height={iconSize} />
        )}
        {icon === 'approve' && (
          <ApproveIcon fill={colors.blue} width={iconSize} height={iconSize} />
        )}
        {icon === 'cookies' && (
          <CookiesIcon
            fill={colors.blue}
            stroke={colors.blue}
            width={iconSize}
            height={iconSize}
          />
        )}
        {icon === 'priceList' && (
          <PriceListIcon
            fill={colors.blue}
            stroke={colors.blue}
            width={iconSize}
            height={iconSize}
          />
        )}
      </Box>
    </Box>
  );
};

export default Title;
