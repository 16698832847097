/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';

import { Box, Button, FormHelperText, Stack, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, Link } from 'react-router-dom';
import { UserCredentials } from '../../../data/login/model';
import { useLogin } from '../../../data/login/hooks';
import Title from '../../shared/Title';
import colors from '../../../assets/colors';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import useBreakpoint from '../../../hooks/useBreakpoint';

const Login: React.FC = () => {
  const { handleSubmit, control } = useForm<UserCredentials>({
    defaultValues: { userName: '', password: '' },
    shouldFocusError: true,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });
  const { mutateAsync: login, isLoading } = useLogin();
  const navigate = useNavigate();
  const [isAuthorize, setIsAuthorize] = useState(true);
  const { isMobile } = useBreakpoint();
  const onSubmit = handleSubmit((data) => {
    login(data)
      .then(() => {
        navigate('/orders');
        window.location.reload();
      })
      .catch(() => setIsAuthorize(false));
  });

  if (isLoading) return <LoaderSpinner isLoading />;

  return (
    <Box
      sx={
        isMobile
          ? { margin: '8%' }
          : { width: 'fit-content', margin: '4% auto' }
      }
    >
      <Title content="Login" icon="key" />

      <Stack sx={isMobile ? {} : { alignItems: 'start' }}>
        <form onSubmit={onSubmit}>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            spacing={3}
            sx={
              isMobile
                ? { alignItems: 'stretch' }
                : {
                    alignItems: 'flex-start',
                    justifyContent: 'space-between',
                    marginBottom: '2rem',
                  }
            }
          >
            <Controller
              control={control}
              name="userName"
              rules={{
                required: { value: true, message: 'Required filed' },
                maxLength: {
                  value: 64,
                  message: 'Password should not exceed 64 characters',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error?.message}
                  label="Cell-Phone"
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />

            <Controller
              control={control}
              name="password"
              rules={{
                required: { value: true, message: 'Required filed' },
                maxLength: {
                  value: 32,
                  message: 'Password should not exceed 32 characters',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  label="Password *"
                  autoComplete="on"
                  variant="standard"
                  type="password"
                  error={!!error?.message}
                  helperText={error?.message}
                />
              )}
            />
          </Stack>
          <Button
            variant="outlined"
            sx={{
              width: '100%',
              marginTop: '2rem',
              padding: '2%',
              border: '2px solid',
            }}
            type="submit"
          >
            Login
          </Button>
          {!isAuthorize && (
            <FormHelperText sx={{ color: colors.red }}>
              Cell-Phone or Password is incorrect
            </FormHelperText>
          )}
        </form>
      </Stack>
      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '8px',
            left: '5px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <Link to={'/CookiesPolicy'}>Cookies policy</Link>
          <Link to={'/PrivacyTerms'}>Privacy terms</Link>
        </Box>
      )}
    </Box>
  );
};

export default Login;
