import React from 'react';
import { Navigate } from 'react-router-dom';
import useToken from '../hooks/useToken';

interface ProtectedRouteProps {
  children: JSX.Element | JSX.Element[];
}
export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const { isLogin } = useToken();
  if (!isLogin) return <Navigate to="/" replace />;
  return <>{children}</>;
};
