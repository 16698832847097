import { Box, Link, styled, Typography } from '@mui/material';
import React from 'react';
import Title from '../../shared/Title';
import cookiesPolicyContract from '../../../data/contracts/cookiesPolicy.json';
import colors from '../../../assets/colors';
import rectangle from '../../../assets/svgs/rectangle.svg';
import useBreakpoint from '../../../hooks/useBreakpoint';

const StyledParagraph = styled(Typography)(() => ({
  margin: '4% auto',
  fontWeight: 400,
  fontSize: '17px',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

const StyledParagraphHeader = styled(Typography)(() => ({
  margin: '4% auto',
  fontWeight: 500,
  fontSize: '17px',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

const StyledList = styled('ul')(() => ({
  listStyleImage: `url(${rectangle})`,
}));

const StyledListItem = styled('li')(() => ({
  marginLeft: '1rem',
  marginRight: '0.5rem',
  marginBottom: '1rem',
}));

const CookiesPolicy: React.FC = () => {
  const { isMobile } = useBreakpoint();
  return (
    <Box
      sx={
        isMobile
          ? { margin: '8%' }
          : { width: 'fit-content', margin: '4% auto', maxWidth: '40rem' }
      }
    >
      <Title content={'Cookies Policy'} icon="cookies" />
      <Box>
        {cookiesPolicyContract.Paragraph0.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledList>
          {cookiesPolicyContract.BulletedList0.map((content, index) => (
            <StyledListItem key={index}>{content}</StyledListItem>
          ))}
        </StyledList>
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle1}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph1.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle2}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph2.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle3}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph3.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle4}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph4.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle5}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph5.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle6}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph6.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle7}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph7.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle8}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph8.map((content, index) => (
          <StyledParagraph key={index}>{content}</StyledParagraph>
        ))}
        <StyledParagraphHeader>
          {cookiesPolicyContract.BoldTitle9}
        </StyledParagraphHeader>
        {cookiesPolicyContract.Paragraph9.map((content, index) =>
          index === 1 ? (
            <Box key={index}>
              <StyledParagraph>{content}</StyledParagraph>
              <Link href="/PrivacyTerms">Privacy notice</Link>
            </Box>
          ) : (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          )
        )}
      </Box>
    </Box>
  );
};

export default CookiesPolicy;
