export const interestCalculation = (total: number, days: number) => {
  const interestFee = total * 0.0004444444444444444 * days;
  const commissionFee = total * 0.01;
  const withCommission = total + interestFee + commissionFee;
  const decimalPoint = withCommission % 1;
  const firstNumberOfDecimalPoint = Math.round(decimalPoint * 10);
  const distancesList = [
    Number(((firstNumberOfDecimalPoint + 1) / 10 - decimalPoint).toFixed(2)),
    Number((firstNumberOfDecimalPoint / 10 + 0.05 - decimalPoint).toFixed(2)),
    Number((firstNumberOfDecimalPoint / 10 - decimalPoint).toFixed(2)),
  ];
  const absList = distancesList.map((x) => Math.abs(x));
  const minDistance = Math.min(...absList);
  return withCommission + minDistance;
};
