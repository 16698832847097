import api from '../axios/api';
import { ViewProducts } from './model';

const BASE_URL = 'Product';
export async function getProducts(): Promise<ViewProducts[]> {
  const res = await api.get<ViewProducts[]>(`${BASE_URL}`);
  return res.data;
}
export async function updateProductsPrice(
  data: {
    productID: string;
    corDisConnectionID: string;
    distributionPriceA?: number | undefined;
    distributionPriceB?: number | undefined;
  }[]
) {
  await api.put<ViewProducts[]>(`${BASE_URL}`, data);
}
