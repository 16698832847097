const colors = {
  white: '#FFFFFF',
  black: '#2C2C2C',
  lightGray: '#F2F5F5',
  gray: '#C4C4C4',
  darkGray: '#707070',
  green: '#23b34b',
  brightRed: '#B81B1F',
  red: '#751114',
  blue: '#228d44',
  yellow: '#93be3e',
};

export default colors;
