import {
  styled,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Theme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import colors from '../../../assets/colors';

const StyledContainer = styled(TableContainer)(() => ({}));

const StyledTitle = styled(TableCell)(() => ({
  borderBottom: `1px solid ${colors.black}`,
  fontSize: '17px',
  padding: '0.8rem',
  paddingLeft: 0,
  fontWeight: 500,
  color: colors.black,
}));

export type Column = { name: string; style?: SxProps<Theme> };

export interface Row<T> {
  accessor: keyof T;
  data?: ReactNode;
  render?: (data: ReactNode) => JSX.Element | string;
}

interface TableProps<T> {
  columns: Column[];
  rows: Row<T>[][];
  pagination?: boolean;
  sx?: SxProps<Theme> | undefined;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TableData = any;

export const CustomTable: React.FC<TableProps<TableData>> = ({
  columns,
  rows,
  pagination,
  sx,
}) => {
  const [page, setPage] = React.useState(0);
  const maxRow = pagination === undefined ? 15 : rows.length;
  const [rowsPerPage, setRowsPerPage] = React.useState(maxRow);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, maxRow));
    setPage(0);
  };
  return (
    <>
      <StyledContainer sx={sx}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow sx={{ boxShadow: '0px 2px 5px 0px #9a9a9a' }}>
              {columns.map((cell, index) => (
                <StyledTitle key={index} sx={cell.style}>
                  {cell.name}
                </StyledTitle>
              ))}
            </TableRow>
          </TableHead>
          <TableBody sx={{ overflow: 'scroll' }}>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow key={index}>
                  {row.map((d, i) => (
                    <TableCell key={i}>
                      {d.render ? d.render(d.data) : d.data}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[15, 20, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledContainer>
    </>
  );
};
