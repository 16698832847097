import {
  Box,
  Button,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
  styled,
  TextField,
} from '@mui/material';

import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoaderSpinner } from '../../../../contexts/spinnerContext';
import { useCreditUpdate } from '../../../../data/retailers/hooks';
import { RetailerCreditModel } from '../../../../data/retailers/model';
import useToken from '../../../../hooks/useToken';
import { moneyFormatter } from '../../../../utils/formatters';
import Title, { StyledFormDescription } from '../../../shared/Title';

const FormContainer = styled(Box)(() => ({
  display: 'grid',
  justifyContent: 'start',
  alignItems: 'center',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '4rem',
  rowGap: '3rem',
}));

interface UpdateCreditRequest {
  amount: string;
  creditPeriod: number;
}
const StyledForm = styled('form')(() => ({
  margin: '0 auto',
}));

const UpdateCredit: React.FC = () => {
  const location = useLocation();
  const { Company } = useToken();
  const data = location.state as RetailerCreditModel;
  const { mutateAsync: updateCredit } = useCreditUpdate();
  const { control, handleSubmit, register } = useForm<UpdateCreditRequest>({
    defaultValues: {
      creditPeriod: data.creditPeriod,
    },
    mode: 'onBlur',
  });

  const navigate = useNavigate();

  const submit = async (formData: UpdateCreditRequest) => {
    await updateCredit({
      id: data.id,
      creditAmount: Number(formData.amount.replaceAll(',', '')),
      creditPeriod: formData.creditPeriod,
    });

    navigate('/CreditChangeMessage', {
      state: {
        storeName: data.storeName,
        creditAmount: Number(formData.amount.replaceAll(',', '')),
        creditPeriod: formData.creditPeriod,
      },
    });
  };

  if (!data) return <LoaderSpinner isLoading />;
  return (
    <Box sx={{ width: 'fit-content', margin: '4% 35%' }}>
      <Box>
        <Title content="Change Retailer's Credit" icon="priceList"></Title>
      </Box>
      <StyledForm onSubmit={handleSubmit((formData) => submit(formData))}>
        <Box sx={{ width: 'fit-content' }}>
          <StyledFormDescription sx={{ paddingBottom: '2rem' }}>
            {data.storeName} current credit amount is KES{' '}
            {moneyFormatter(data.creditAmount)}, period {data.creditPeriod}{' '}
            days. If changed, the system will update
          </StyledFormDescription>
          <FormContainer>
            <Box>
              <TextField
                {...register('amount')}
                sx={{ maxWidth: '200px', minWidth: '200px' }}
                label="New Credit Amount"
                variant="standard"
                defaultValue={moneyFormatter(data.creditAmount)}
                onBlur={(e) => {
                  e.target.value =
                    moneyFormatter(
                      Number(e.target.value.replaceAll(',', ''))
                    ) || e.target.value;
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment
                      sx={{ margin: 0, marginRight: '0.5rem', padding: 0 }}
                      position="end"
                    >
                      KES
                    </InputAdornment>
                  ),
                }}
              />
              <FormHelperText>Insert 0 to eliminate use </FormHelperText>
              <FormHelperText>KES 1,000 to KES 300,000 </FormHelperText>
            </Box>
            <Controller
              name="creditPeriod"
              control={control}
              defaultValue={data.creditPeriod}
              render={({ field }) => (
                <Box>
                  <Select
                    {...field}
                    label="New Credit Period"
                    sx={{
                      maxWidth: '200px',
                      minWidth: '200px',
                    }}
                    variant="standard"
                  >
                    <MenuItem value={0}> 0 </MenuItem>
                    <MenuItem value={7}> 7 </MenuItem>
                    <MenuItem value={14}> 14 </MenuItem>
                    <MenuItem value={21}> 21 </MenuItem>
                    <MenuItem value={30}> 30 </MenuItem>
                  </Select>
                  <FormHelperText> Select 0 to eliminate use </FormHelperText>
                </Box>
              )}
            />
            <TextField
              label="Approved By"
              sx={{ maxWidth: '200px', minWidth: '200px' }}
              variant="standard"
              disabled={true}
              value="NOBLE OUTLOOK LIMITED "
            />
            <TextField
              label="Change By"
              sx={{ maxWidth: '200px', minWidth: '200px' }}
              variant="standard"
              disabled={true}
              value={Company}
            />
          </FormContainer>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              width: '100%',
              marginTop: '5rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            onClick={() => navigate('/RetailersCredit')}
            color="info"
            sx={{
              width: '100%',
              marginTop: '2rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Back
          </Button>
        </Box>
      </StyledForm>
    </Box>
  );
};

export default UpdateCredit;
