import { Box, Button, styled, Typography } from '@mui/material';
import React, { useMemo } from 'react';
import colors from '../../../assets/colors';

import { ReactComponent as AppLogo } from '../../../assets/svgs/btbrco.svg';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { ReactComponent as MobileLogo } from '../../../assets/svgs/btbrco-mobile.svg';

import useToken from '../../../hooks/useToken';
import NavBar from './NavBar';

const StyledHeader = styled('div')(() => ({
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 5,
  height: '4.5rem',
  width: '100%',
  alignItems: 'center',
  display: 'flex',
  boxShadow: '0px 2px 5px 0px #9a9a9a',
  backgroundColor: colors.white,
  justifyContent: 'space-between',
}));

const StyledContainer = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '1.5rem',
  padding: '0 0 0 2rem',
});

const StyledHeaderItem = styled('div')({});
interface HeaderProps {
  rightLogo?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  leftLogo?: React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & { title?: string | undefined }
  >;
  userName?: string;
}

const Header: React.FC<HeaderProps> = () => {
  const { isMobile } = useBreakpoint();
  const { Company, isLogin } = useToken();
  const handleClick = () => {
    if (Company) window.location.href = '/orders';
    else window.location.href = '/';
  };

  const menuItems: { content: string; link: string }[] = useMemo(() => {
    if (!isLogin) {
      return isMobile
        ? [
            { content: 'Login', link: '/login' },
            { content: 'Contact-Us', link: '/contactUs' },
            { content: 'Privacy & Terms', link: '/privacyTerms' },
            { content: 'Cookies Policy ', link: '/CookiesPolicy' },
          ]
        : [];
    }
    if (Company !== 'BTA')
      return isMobile
        ? [
            { content: 'Your orders', link: '/Orders' },
            { content: 'Products', link: '/Products' },
            { content: 'Retailers credit', link: '/RetailersCredit' },
            { content: 'Contact-Us', link: '/contactUs' },
          ]
        : [
            { content: 'Your orders', link: '/Orders' },
            { content: 'Products', link: '/Products' },
            { content: 'Retailers credit', link: '/RetailersCredit' },
            { content: 'Contact-Us', link: '/contactUs' },
          ];
    return [
      { content: 'Your orders', link: '/Orders' },
      { content: 'Contact-Us', link: '/contactUs' },
    ];
  }, [isLogin, Company]);

  return (
    <StyledHeader>
      <StyledContainer>
        <StyledHeaderItem sx={isMobile ? {} : { margin: '0 2rem' }}>
          {isMobile ? (
            <MobileLogo onClick={() => handleClick()} />
          ) : (
            <AppLogo onClick={() => handleClick()} />
          )}
        </StyledHeaderItem>
        <StyledHeaderItem>
          <NavBar menuItems={menuItems} />
        </StyledHeaderItem>
      </StyledContainer>
      <StyledHeaderItem>
        {!isMobile && (
          <Box
            sx={{
              margin: '0 4rem',
              display: 'flex',
              gap: '3rem',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <Box sx={{ marginRight: '1rem' }}>
              <Typography
                variant="body2"
                sx={{ marginRight: '1rem' }}
                color={colors.gray}
              >
                {Company}
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{ fontWeight: 500, fontSize: '0.8rem' }}
                color={colors.black}
              >
                Confirmed retailer?
              </Typography>
              <Typography sx={{ fontSize: '0.8rem' }} color={colors.blue}>
                Order now on *501*130#
              </Typography>
            </Box>
            <Button
              color="primary"
              variant="outlined"
              style={
                isMobile ? { fontSize: '12px', padding: '0.5rem 1rem' } : {}
              }
              onClick={() => {
                localStorage.removeItem('token');
                window.location.href = '/login';
              }}
            >
              {isLogin ? 'LOG OUT' : 'Login'}
            </Button>
          </Box>
        )}
      </StyledHeaderItem>
    </StyledHeader>
  );
};

export default Header;
