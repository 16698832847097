import { Box, Button, InputAdornment, styled, TextField } from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import { useOrderUpdate } from '../../../data/order/hooks';
import useToken from '../../../hooks/useToken';
import { interestCalculation } from '../../../utils/calc';
import { moneyFormatter } from '../../../utils/formatters';
import Title, { StyledFormDescription } from '../../shared/Title';

interface PaymentProps {
  orderID: string;
  customerType: 'retailer' | 'distributor';
  amount: string;
  payerName: string;
  approvalTS: Date;
}
interface PaymentRequest {
  payBill?: string;
  transferDate: string;
}
const FormContainer = styled(Box)(() => ({
  display: 'grid',
  justifyContent: 'start',
  alignItems: 'start',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '4rem',
  rowGap: '3rem',
}));

const defaultValues: PaymentRequest = {
  payBill: '',
  transferDate: moment().format('YYYY-MM-DD'),
};
const OrderPayment: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { orderID, customerType, amount, approvalTS } = state as PaymentProps;
  const firstAmount = amount;

  const { mutateAsync: updateOrderStatus, isLoading } = useOrderUpdate();
  const { control, handleSubmit } = useForm<PaymentRequest>({
    defaultValues: {
      ...defaultValues,
    },
    mode: 'onBlur',
  });
  const { name } = useToken();
  const [totalAmount, setTotalAmount] = useState(
    customerType === 'retailer'
      ? interestCalculation(Number(firstAmount), 1)
      : Number(amount) ?? 0
  );
  const submit = async (data: PaymentRequest) => {
    await updateOrderStatus({
      orderID,
      payBillNumber: data.payBill,
      customerType: customerType === 'retailer' ? 1 : 2,
      amount: totalAmount,
      paymentDate: data.transferDate,
    });

    navigate('/orders');
  };
  if (isLoading) return <LoaderSpinner isLoading />;

  return (
    <Box sx={{ width: 'fit-content', margin: '4% auto' }}>
      <Title
        content={`${
          customerType === 'retailer' ? 'Retailer' : 'Distributor'
        } Order Paid`}
        icon="payment"
      />
      <form onSubmit={handleSubmit((data) => submit(data))}>
        <Box sx={{ width: 'fit-content' }}>
          <StyledFormDescription sx={{ paddingBottom: 0 }}>
            The order will be marked as paid.
          </StyledFormDescription>
          <FormContainer>
            <TextField
              sx={{ maxWidth: '200px', minWidth: '200px' }}
              label="Amount *"
              disabled
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    sx={{ margin: 0, marginRight: '0.5rem', padding: 0 }}
                    position="end"
                  >
                    KES
                  </InputAdornment>
                ),
              }}
              value={moneyFormatter(totalAmount)}
            />
            <Controller
              name="transferDate"
              control={control}
              rules={{
                required: { value: true, message: 'Required field' },
              }}
              render={({
                field: { onBlur, onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  type="date"
                  error={!!error?.message}
                  label="Transfer date *"
                  sx={{ maxWidth: '200px', minWidth: '200px' }}
                  value={value}
                  inputProps={{
                    min: moment().format('yyyy-MM-DD'),
                  }}
                  onChange={(e) => {
                    if (customerType === 'retailer') {
                      const date = moment(e.target.value);
                      const date1 = moment(approvalTS);
                      const days = date.diff(date1, 'days');
                      const min = date.diff(date1, 'minute');
                      if (min >= 0) {
                        setTotalAmount(
                          interestCalculation(Number(firstAmount), days + 2)
                        );
                      } else {
                        setTotalAmount(
                          interestCalculation(Number(firstAmount), days + 1)
                        );
                      }
                    }
                    onChange(e);
                  }}
                  onBlur={onBlur}
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="payBill"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error?.message}
                  label="Pay bill"
                  sx={{ maxWidth: '200px', minWidth: '200px' }}
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />
            <TextField
              label="Approved by *"
              sx={{ maxWidth: '200px', minWidth: '200px' }}
              variant="standard"
              disabled={true}
              value={name}
            />
          </FormContainer>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              width: '100%',
              marginTop: '5rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="info"
            sx={{
              width: '100%',
              marginTop: '2rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
            onClick={() => navigate('/Orders')}
          >
            Back to all orders
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default OrderPayment;
