import { Button, Stack, styled } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colors from '../../../../assets/colors';
import { ReactComponent as ViewOrder } from '../../../../assets/svgs/viewOrder.svg';
import { ReactComponent as CancelOrder } from '../../../../assets/svgs/reject.svg';
import { ReactComponent as ApproveIcon } from '../../../../assets/svgs/approve.svg';

import { RetailerOrderModel } from '../../../../data/order/model';
import useToken from '../../../../hooks/useToken';

interface ActionProps {
  name?: string;
  data?: RetailerOrderModel;
  orderStatus?: number;
  isPaid?: boolean;
}

const StyledButton = styled(Button)({
  padding: '3%',
  justifyContent: 'flex-start',
  gap: '2rem',
  paddingLeft: '1rem',
});

const Action: React.FC<ActionProps> = ({ orderStatus, data, isPaid }) => {
  const [isHoverOrderButton, setIsOrderMoneyButton] = useState(false);
  const [isHoverCancelButton, setIsOrderCancelButton] = useState(false);
  const navigate = useNavigate();
  const { Company } = useToken();

  return (
    <Stack sx={{ gap: '15px', width: '100%' }}>
      <StyledButton
        onMouseEnter={() => setIsOrderMoneyButton(!isHoverOrderButton)}
        onMouseLeave={() => setIsOrderMoneyButton(!isHoverOrderButton)}
        sx={{
          '&:hover': {
            backgroundColor: colors.blue,
          },
        }}
        variant="outlined"
        color="info"
        onClick={() => {
          navigate('/OrderDetails', {
            state: {
              storeName: data?.storeName,
              customerNumber: data?.customerNumber,
              orderStatus: data?.statusName,
              orderID: data?.orderID,
            },
          });
        }}
      >
        <ViewOrder fill={isHoverOrderButton ? colors.white : colors.blue} />
        View order
      </StyledButton>
      {orderStatus === 4 && isPaid === false && Company !== 'BAT' && (
        <StyledButton
          onMouseEnter={() => setIsOrderCancelButton(!isHoverCancelButton)}
          onMouseLeave={() => setIsOrderCancelButton(!isHoverCancelButton)}
          variant="outlined"
          color="error"
          onClick={() => {
            navigate('/CancelOrder', {
              state: {
                orderID: data?.orderID,
                storeName: data?.storeName,
              },
            });
          }}
        >
          <CancelOrder fill={isHoverCancelButton ? colors.white : colors.red} />
          Cancel order
        </StyledButton>
      )}
      {orderStatus === 1 && Company !== 'BAT' && (
        <StyledButton
          onMouseEnter={() => setIsOrderCancelButton(!isHoverCancelButton)}
          onMouseLeave={() => setIsOrderCancelButton(!isHoverCancelButton)}
          variant="outlined"
          color="success"
          onClick={() => {
            navigate('/ApproveOrder', {
              state: {
                storeName: data?.storeName,
                orderID: data?.orderID,
                orderTotal: data?.orderAmount,
              },
            });
          }}
          sx={{ border: `1px solid`, color: colors.darkGray }}
        >
          <ApproveIcon
            fill={isHoverCancelButton ? colors.white : colors.green}
          />
          Approve order
        </StyledButton>
      )}
    </Stack>
  );
};

export default Action;
