import api from '../axios/api';
import {
  OrdersLine,
  RetailerOrderModel,
  UpdateOrderRequest as PayOrderRequest,
} from './model';

const BASE_URL = 'ViewRetailerOrder';
export async function getOrder(): Promise<RetailerOrderModel[]> {
  const res = await api.get<RetailerOrderModel[]>(`${BASE_URL}`);
  return res.data;
}

export async function payOrder(data: PayOrderRequest) {
  return api.put('RetailerOrder/UpdateOrderPaid', data);
}

export async function getOrderLines(orderId: string) {
  const res = await api.get<OrdersLine[]>(`OrderLine/${orderId}`);
  return res.data;
}

const BASE_RETAILER_URL = 'RetailerOrder';
export async function sendRemainder(data: {
  orderID?: string;
  customerType: 'retailer' | 'distributor';
}) {
  const res = await api.post(`${BASE_RETAILER_URL}/Remainder`, {
    ...data,
    customerType: data.customerType === 'retailer' ? 1 : 2,
  });
  return res.data;
}

export async function updateStatus(data: {
  orderID: string;
  newStatus: number;
}) {
  const res = await api.put(`${BASE_RETAILER_URL}/UpdateStatus`, {
    ...data,
    storeID: '',
  });
  return res.data;
}
