import React from 'react';

import { styled } from '@mui/material';
import colors from '../../../assets/colors';

interface StatusBadgeProps {
  color: 'error' | 'warning' | 'success' | 'info';
  text: string;
}

const dotColor = {
  error: colors.red,
  warning: colors.yellow,
  info: colors.blue,
  success: colors.green,
};

const Dot = styled('div')({
  borderRadius: '50%',
  marginRight: '7px',
  width: '10px',
  height: '10px',
});

const StyledSpan = styled('span')({
  fontSize: '14px',
  fontWeight: 400,
});

const StyledContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
const StatusBadge: React.FC<StatusBadgeProps> = ({ color, text }) => (
  <StyledContainer>
    <Dot sx={{ border: `2px solid ${dotColor[color]}` }}></Dot>
    <StyledSpan>{text === 'pending' ? 'Pending' : text}</StyledSpan>
  </StyledContainer>
);

export default StatusBadge;
