export const numberFormatter = (
  input: string | number | undefined,
  currency?: 'KES'
) => `${currency ?? 'KES'} ${input?.toLocaleString('en-US')} `;

export const moneyFormatter = (input: number | undefined) => {
  if (!input) return undefined;
  return input.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const convertMSToDate = (ms: number) => {
  const totalSeconds = Math.floor(ms / 1000);
  const totalMinutes = Math.floor(totalSeconds / 60);
  const totalHours = Math.floor(totalMinutes / 60);

  const days = Math.floor(totalHours / 24);
  const hours = Math.floor(totalHours % 24);
  const seconds = totalSeconds % 60;
  const minutes = totalMinutes % 60;
  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

export const timePassed = (date: Date) => {
  const msBetweenDates = Date.now() - new Date(date).getTime();
  return convertMSToDate(msBetweenDates);
};

export const phoneNumberFormatter = (input: string) => {
  const prefix = input.substring(0, 4);
  return `${prefix} ${' '} ${input.substring(5)}`;
};
