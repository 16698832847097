import { createTheme } from '@mui/material';
import colors from '../assets/colors';

export const theme = createTheme({
  palette: {
    primary: {
      main: colors.blue,
    },
    secondary: {
      main: colors.darkGray,
    },
    error: {
      main: colors.red,
    },
    info: {
      main: colors.gray,
    },
    warning: {
      main: colors.yellow,
    },
    success: {
      main: colors.green,
    },
  },
  components: {
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#228d44',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            border: '1px solid',
            padding: '0.2rem 2.5rem',
          }),
          fontFamily: 'roboto',
          borderRadius: 0,
          minWidth: 'fit-content',
          fontWeight: 500,
        }),
        outlinedPrimary: {
          color: colors.blue,
          '&:hover': {
            border: '1px solid',
            backgroundColor: colors.blue,
            color: colors.white,
          },
        },
        outlinedSuccess: {
          '&:hover': {
            border: '1px solid',
            backgroundColor: colors.green,
            color: colors.white,
          },
        },
        outlinedWarning: {
          '&:hover': {
            backgroundColor: colors.yellow,
            color: colors.black,
          },
        },
        outlinedError: {
          border: `1px solid`,
          color: colors.darkGray,
          '&:hover': {
            border: '1px solid',
            backgroundColor: colors.red,
            color: colors.white,
          },
        },
        outlinedInfo: {
          border: `1px solid`,
          color: colors.darkGray,
          '&:hover': {
            border: `1px solid`,
            backgroundColor: colors.darkGray,
            color: colors.white,
          },
        },
        outlinedSecondary: {
          '&:hover': {
            backgroundColor: colors.darkGray,
            color: colors.white,
          },
        },
        containedPrimary: {
          '&:hover': {
            backgroundColor: colors.blue,
            color: colors.white,
          },
        },
        textPrimary: {
          '&:hover': {
            backgroundColor: colors.blue,
            color: colors.white,
          },
        },
        textSuccess: {
          '&:hover': {
            backgroundColor: colors.green,
            color: colors.white,
          },
        },
        textError: {
          '&:hover': {
            backgroundColor: colors.red,
            color: colors.white,
          },
        },
        textInfo: {
          color: colors.darkGray,
          '&:hover': {
            backgroundColor: colors.darkGray,
            color: colors.white,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '2rem',
          paddingLeft: 0,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          verticalAlign: 'top',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(253 253 253 / 76%)',
        },
      },
    },
  },
});
