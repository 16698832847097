/* eslint-disable @typescript-eslint/no-unused-vars */
import { Box, Button, styled } from '@mui/material';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoaderSpinner } from '../../../../contexts/spinnerContext';
import { useRetailerCredit } from '../../../../data/retailers/hooks';
import { RetailerCreditModel } from '../../../../data/retailers/model';
import { numberFormatter } from '../../../../utils/formatters';
import Title from '../../../shared/Title';
import { Column, CustomTable, Row } from '../../UIComponents/Table';
import RetailerInfo from '../Shared/RetailerInfo';
import UpdateCredit from './UpdateCredit';
import { ReactComponent as PriceListIcon } from '../../../../assets/svgs/priceList.svg';
import colors from '../../../../assets/colors';

const StyledButton = styled(Button)({
  padding: '0.3rem 0',
  paddingRight: '3rem',
  justifyContent: 'flex-start',
  gap: '2rem',
  paddingLeft: '1rem',
});

const RetailerCredit: React.FC = () => {
  const { data: retailersCredit } = useRetailerCredit();
  const navigate = useNavigate();
  const createTable = (data: RetailerCreditModel[]) => {
    const columns: Column[] = [
      { name: 'Retailer Information', style: { width: '10%' } },
      { name: 'Distributor', style: { width: '7%' } },
      { name: 'Credit Period', style: { width: '7%' } },
      { name: 'Credit Amount', style: { width: '10%' } },
      { name: 'Last Update Date', style: { width: '7%' } },
      { name: 'Actions', style: { width: '10%' } },
    ];
    let row: Row<RetailerCreditModel>[] = [];
    const rows: Row<RetailerCreditModel>[][] = [];
    data.forEach((element) => {
      row.push({
        accessor: 'storeName',
        data: element.storeName,
        render: () => (
          <RetailerInfo
            orderId={element.pdkuuid}
            storeName={element.storeName}
          />
        ),
      });
      row.push({ accessor: 'distributorName', data: element.distributorName });
      row.push({ accessor: 'Credit Period', data: element.creditPeriod });
      row.push({
        accessor: 'orderAmount',
        data: element.orderAmount,
        render: () => <span>{numberFormatter(element.creditAmount)} </span>,
      });
      row.push({
        accessor: 'distributorEstimatedPaymentTS',
        data: element.statusName,
        render: () => (
          <span>{moment(element.lastUpdate).format('DD/MM/YYYY')}</span>
        ),
      });
      row.push({
        accessor: 'retailerEstimatedPaymentTS',
        data: element.statusName,
        render: () => (
          <StyledButton
            variant="outlined"
            color="info"
            onClick={() => navigate('/UpdateCredit', { state: element })}
          >
            <PriceListIcon fill={colors.black} />
            Update Credit
          </StyledButton>
        ),
      });
      rows.push(row);
      row = [];
    });

    return { rows, columns };
  };
  if (retailersCredit === undefined) return <LoaderSpinner isLoading={true} />;
  const tableData = createTable(retailersCredit);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          margin: '4% 0 0 0',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Title
          sx={{
            fontSize: '2.9rem',
            margin: '4% 0',
            textAlign: 'start',
            lineHeight: '3.8rem',
          }}
          content="Retailer's Credit Decisions"
        ></Title>
      </Box>

      <Box>
        <CustomTable {...tableData}></CustomTable>
      </Box>
    </>
  );
};

export default RetailerCredit;
