import { AxiosError } from 'axios';
import {
  QueryFunction,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { useNavigate } from 'react-router-dom';

function useCostumeQuery<
  TQueryFnData = unknown,
  TError = AxiosError,
  TData = TQueryFnData
>(
  queryKey: QueryKey,
  queryFn: QueryFunction<TQueryFnData>,
  options?: UseQueryOptions<TQueryFnData, TError, TData>
): UseQueryResult<TData, TError> {
  const navigate = useNavigate();
  return useQuery(queryKey, queryFn, {
    ...options,
    onError: (error: TError) => {
      if ((error as AxiosError).response?.status === 401) {
        localStorage.removeItem('token');
        navigate('/login');
      }
    },
  });
}

export { useCostumeQuery as useQuery };
