export enum OrderStatus {
  Pending = 1,
  Canceled = 2,
  Rejected = 3,
  Approved = 4,
  Paid = 5,
}
export enum PaymentStatus {
  Pending = 1,
  Approved = 3,
}
