import { useTheme, useMediaQuery } from '@mui/material';

const useBreakpoint = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(590));
  const isTablet = useMediaQuery(theme.breakpoints.down(1160));
  const isOnlyTablet = useMediaQuery(theme.breakpoints.between(590, 1160));

  return {
    isMobile,
    isTablet,
    isOnlyTablet,
  };
};

export default useBreakpoint;
