import { useQuery, useMutation } from 'react-query';
import { getRetailerCredit, updateRetailerCredit } from './dataService';

export const useRetailerCredit = () =>
  useQuery('retailerCredit', () => getRetailerCredit());

export const useCreditUpdate = () =>
  useMutation(
    (data: { id: string; creditAmount: number; creditPeriod: number }) =>
      updateRetailerCredit(data)
  );
