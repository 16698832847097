import { Box, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import { usePersonalData } from '../../../../data/PII/hooks';
import { phoneNumberFormatter } from '../../../../utils/formatters';

interface RetailerInfoProps {
  storeName?: string;
  orderId: string;
}

const RetailerInfo: React.FC<RetailerInfoProps> = ({ storeName, orderId }) => {
  const { data, refetch, isLoading } = usePersonalData(orderId);
  return (
    <>
      <Box>
        <Typography variant="body1">{storeName}</Typography>
        {data !== undefined ? (
          <Typography variant="body1">{phoneNumberFormatter(data)}</Typography>
        ) : (
          <LoadingButton
            sx={{ paddingLeft: '0' }}
            variant="text"
            loadingIndicator="Loading…"
            loading={isLoading}
            onClick={() => {
              refetch();
            }}
          >
            Show Personal Data
          </LoadingButton>
        )}
      </Box>
    </>
  );
};

export default RetailerInfo;
