import api from '../axios/api';
import { RetailerCreditModel } from './model';

const BASE_URL = 'Retailer';
export async function getRetailerCredit(): Promise<RetailerCreditModel[]> {
  const res = await api.get<RetailerCreditModel[]>(`${BASE_URL}/Credit`);
  return res.data;
}

export async function updateRetailerCredit(data: {
  id: string;
  creditAmount: number;
  creditPeriod: number;
}) {
  await api.put(`${BASE_URL}/Credit`, data);
}
