import { Box, Button, Link, Stack, Typography } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import { useRemainder } from '../../../data/order/hooks';
import { numberFormatter } from '../../../utils/formatters';
import TitleWithIcon from '../../shared/Title';

interface RemainderInfo {
  customerType: 'retailer' | 'distributor';
  paymentAmount: number;
  name: string;
  orderID: string;
  orderStatus: number;
  customerNumber: number;
  creditPeriod: number;
  date: Date;
}

const GenerateRemainderText = (data: RemainderInfo) =>
  data.customerType === 'distributor'
    ? `Payment of ${numberFormatter(
        data.paymentAmount
      )} was not marked as received by ${'\n'}
      distributor ${
        data.name
      }. The order was approved by them on ${'\n'} ${moment(data.date).format(
        'L'
      )} at ${moment(data.date).format('hh:mm')} ${'\n'} 
      `
    : `Payment of ${numberFormatter(
        data.paymentAmount
      )} was not marked as received by ${'\n'}
    retailer ${data.name}. The latest payment date ${'\n'} ${moment(data.date)
        .add(data.creditPeriod - 1, 'days')
        .format('DD/MM/YYYY')}.${'\n'} 
    `;

const Remainder: React.FC = () => {
  const location = useLocation();
  const data = location.state as RemainderInfo;
  const navigate = useNavigate();
  const { orderID } = useParams<string>();
  const { mutateAsync: sendRemainder, isLoading } = useRemainder();

  if (isLoading) return <LoaderSpinner isLoading />;
  return (
    <Box sx={{ width: 'fit-content', margin: '4% auto' }}>
      {(location.state as RemainderInfo).customerType === 'distributor' ? (
        <TitleWithIcon
          content="Send Reminder To Distributor"
          icon="remainder"
        />
      ) : (
        <TitleWithIcon content="Send Reminder To Retailer" icon="remainder" />
      )}
      <Stack>
        <Box sx={{ alignSelf: 'start' }}>
          {GenerateRemainderText(location.state as RemainderInfo)
            .split('\n')
            .map((text, index) => (
              <Typography
                key={index}
                variant="subtitle1"
                sx={{ textAlign: 'start', fontSize: '1.1rem' }}
              >
                {text}
              </Typography>
            ))}
          <Typography
            variant="subtitle1"
            sx={{ textAlign: 'start', fontSize: '1.1rem', marginTop: '0.5rem' }}
          ></Typography>
          <Typography
            variant="subtitle1"
            sx={{ textAlign: 'start', fontSize: '1.1rem' }}
          >
            You can see the order information at{' '}
            <Link
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                navigate('/OrderDetails', {
                  state: {
                    storeName: data?.name,
                    customerNumber: data?.customerNumber,
                    orderStatus: data?.orderStatus,
                    orderID: data?.orderID,
                  },
                });
              }}
            >
              order information
            </Link>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'start',
            flexDirection: 'column',
            width: '30rem',
          }}
        >
          <Button
            variant="outlined"
            color="primary"
            sx={{
              border: '2px solid',
              marginBottom: '1.5rem',
              marginTop: '5rem',
              padding: '0.5rem',
              '&:hover': {
                border: '2px solid',
              },
            }}
            onClick={async () => {
              await sendRemainder({
                orderID,
                customerType: (location.state as RemainderInfo).customerType,
              });

              navigate('/orders');
            }}
          >
            Send Reminder
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={() => navigate('/orders')}
            sx={{
              padding: '0.5rem',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Back to all orders
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default Remainder;
