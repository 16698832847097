import { useQuery } from '../utils/reactQuery';
import { getCellPhone, getCellPhoneByPDKUUID } from './dataService';

export const usePersonalData = (orderId: string) =>
  useQuery(['personalData', orderId], () => getCellPhone(orderId), {
    enabled: false,
  });

export const usePersonalDataByPDKKUID = (pdkuuid: string) =>
  useQuery(['personalData', pdkuuid], () => getCellPhoneByPDKUUID(pdkuuid), {
    enabled: false,
  });
