import React from 'react';

import './App.css';
import './fonts.css';
import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from 'react-router-dom';
import { theme } from '../src/style/theme';
import Header from './components/pages/UIComponents/Header';
import ContactUsLogo from './components/ContactUsLogo';
import { router } from './Router';
import { SpinnerProvider } from './contexts/spinnerContext';
import ErrorPage from './components/ErrorPage';
import useBreakpoint from './hooks/useBreakpoint';

const queryClient = new QueryClient();
function App() {
  const { isMobile } = useBreakpoint();

  return (
    <React.StrictMode>
      <ThemeProvider theme={theme}>
        <SpinnerProvider>
          <QueryClientProvider client={queryClient}>
            <Header />
            <Box
              style={
                isMobile
                  ? { width: '100%' }
                  : { padding: '0 5rem ', marginBottom: '12rem' }
              }
            >
              <RouterProvider fallbackElement={<ErrorPage />} router={router} />
            </Box>
            {!isMobile && <ContactUsLogo />}
          </QueryClientProvider>
        </SpinnerProvider>
      </ThemeProvider>
    </React.StrictMode>
  );
}

export default App;
