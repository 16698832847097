/* eslint-disable react/no-unescaped-entities */
import { Box, Link, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import colors from '../../../assets/colors';
import Title from '../../shared/Title';
import rectangle from '../../../assets/svgs/rectangle.svg';
import circle from '../../../assets/svgs/circle.svg';

import privacyTermContract from '../../../data/contracts/PrivacyAndTerms.json';
import useBreakpoint from '../../../hooks/useBreakpoint';

const StyledSubTitle = styled(Typography)(() => ({
  margin: '4% auto',
  fontWeight: 500,
  fontSize: '23px',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

const StyledParagraph = styled(Typography)(() => ({
  margin: '4% auto',
  fontWeight: 400,
  fontSize: '17px',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

const StyledParagraphHeader = styled(Typography)(() => ({
  margin: '4% auto',
  fontWeight: 500,
  fontSize: '17px',
  paddingBottom: '1.5rem',
  color: colors.black,
}));

const StyledList = styled('ul')(() => ({
  listStyleImage: `url(${rectangle})`,
}));

const StyledListItem = styled('li')(() => ({
  marginLeft: '1rem',
  marginRight: '0.5rem',
  marginBottom: '1rem',
}));
const PrivacyAndTerms: React.FC = () => {
  const { isMobile } = useBreakpoint();

  return (
    <>
      <Box
        sx={
          isMobile
            ? { margin: '8%' }
            : { width: 'fit-content', margin: '4% auto', maxWidth: '40rem' }
        }
      >
        <Title content="Privacy & Terms" icon="terms" />
        <StyledSubTitle>Our Privacy Notice</StyledSubTitle>
        <Box>
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader1}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader1.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            Crater Business Centre, 5th Floor, Mombasa Road, Nairobi, Kenya.
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph1Address.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          {privacyTermContract.Paragraph1part2.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader2}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader2.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle1}
          </StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList1.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
            <StyledList
              sx={{ marginLeft: '2rem', listStyleImage: `url(${circle})` }}
            >
              {privacyTermContract.BulletedListIntern1.map((content, index) => (
                <StyledListItem key={index}>{content}</StyledListItem>
              ))}
            </StyledList>
          </StyledList>

          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle2}
          </StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList2.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle3}
          </StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList3.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
            <StyledList
              sx={{ marginLeft: '2rem', listStyleImage: `url(${circle})` }}
            >
              {privacyTermContract.BulletedListIntern3.map((content, index) => (
                <StyledListItem key={index}>{content}</StyledListItem>
              ))}
            </StyledList>
          </StyledList>
          {privacyTermContract.Paragraph2.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList4.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph3.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList5.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
            <StyledList
              sx={{ marginLeft: '2rem', listStyleImage: `url(${circle})` }}
            >
              {privacyTermContract.BulletedListIntern5.map((content, index) => (
                <StyledListItem key={index}>{content}</StyledListItem>
              ))}
            </StyledList>
          </StyledList>

          {privacyTermContract.Paragraph4.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle4}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph5.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle5}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph6.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle6}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph7.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle7}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph8.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle8}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph9.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle9}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph10.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList6.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph11.map((content, index) =>
            index === 2 ? (
              <Box sx={{ marginBottom: '0.5rem' }} key={index}>
                <StyledParagraph>{content}</StyledParagraph>
                <Link href="/CookiesPolicy">Cookies policy</Link>
              </Box>
            ) : (
              <StyledParagraph key={index}>{content}</StyledParagraph>
            )
          )}
          <StyledList>
            {privacyTermContract.BulletedList7.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph12.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList8.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph13.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList9.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph14.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList10.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
            <StyledList
              sx={{ marginLeft: '2rem', listStyleImage: `url(${circle})` }}
            >
              {privacyTermContract.BulletedListIntern10.map(
                (content, index) => (
                  <StyledListItem key={index}>{content}</StyledListItem>
                )
              )}
            </StyledList>
          </StyledList>
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle10}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph15.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList11.map((content, index) => (
              <StyledParagraph key={index}>{content}</StyledParagraph>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph16.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle11}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph17.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader3}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader3.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader4}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader4.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader5}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader5.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList12.map((content, index) => (
              <StyledParagraph key={index}>{content}</StyledParagraph>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph18.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle12}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph19.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList13.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph20.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle13}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph21.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList14.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle14}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph22.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle15}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph23.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <Stack style={{ lineHeight: '0rem' }}>
            {privacyTermContract.ParagraphProcessing.map((content, index) => (
              <StyledParagraph key={index}>{content}</StyledParagraph>
            ))}
          </Stack>
          {privacyTermContract.Paragraph24.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader6}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader6.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader7}
          </StyledParagraphHeader>
          {privacyTermContract.ParagraphWithHeader7.map((content, index) =>
            index === 2 ? (
              <Box sx={{ marginBottom: '0.5rem' }} key={index}>
                <StyledParagraph>{content}</StyledParagraph>
                <Link href="/CookiesPolicy">Cookies policy</Link>
              </Box>
            ) : (
              <StyledParagraph key={index}>{content}</StyledParagraph>
            )
          )}
          <StyledParagraphHeader>
            {privacyTermContract.ParagraphHeader8}
          </StyledParagraphHeader>
          <StyledList>
            <StyledParagraphHeader>
              To deliver our products and services (including insurance):{' '}
            </StyledParagraphHeader>

            {privacyTermContract.BulletedList15.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledList>
            <StyledParagraphHeader>
              Digital Credit Provider (DCP) operations support:
            </StyledParagraphHeader>

            {privacyTermContract.BulletedList16.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>
            {privacyTermContract.BoldTitle16}
          </StyledParagraphHeader>

          {privacyTermContract.Paragraph25.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledList>
            {privacyTermContract.BulletedList17.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>Risk management:</StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList18.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>
            Online Digital Credit Provider (DCP), Mobile Apps and other online
            product platforms:{' '}
          </StyledParagraphHeader>

          <StyledList>
            {privacyTermContract.BulletedList19.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>
            Product and service improvement:
          </StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList20.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>Data analytics:</StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList21.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>Marketing:</StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList22.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledList>
            {privacyTermContract.BulletedList23.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          <StyledParagraphHeader>
            Protecting our legal rights:
          </StyledParagraphHeader>
          <StyledList>
            {privacyTermContract.BulletedList24.map((content, index) => (
              <StyledListItem key={index}>{content}</StyledListItem>
            ))}
          </StyledList>
          {privacyTermContract.Paragraph26.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader style={{ marginTop: '0' }}>
            {privacyTermContract.BoldTitle17}
          </StyledParagraphHeader>
          {privacyTermContract.Paragraph27.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          <StyledParagraphHeader>IKO-STOCK.</StyledParagraphHeader>
          {privacyTermContract.Paragraph28.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
          {privacyTermContract.Paragraph29.map((content, index) => (
            <StyledParagraph key={index}>{content}</StyledParagraph>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default PrivacyAndTerms;
