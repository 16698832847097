import { useQuery, useMutation } from 'react-query';
import { getProducts, updateProductsPrice } from './dataService';

export const useGetProducts = () => useQuery('Products', () => getProducts());

export const usePriceUpdate = () =>
  useMutation(
    (
      data: {
        productID: string;
        corDisConnectionID: string;
        distributionPriceA?: number | undefined;
        distributionPriceB?: number | undefined;
      }[]
    ) => updateProductsPrice(data)
  );
