import React, { useState } from 'react';
import { Box, Button, Stack, styled, Typography } from '@mui/material';

import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as RemainderIcon } from '../../../../assets/svgs/remainder.svg';
import { ReactComponent as PaymentIcon } from '../../../../assets/svgs/payment.svg';
import colors from '../../../../assets/colors';
import { numberFormatter } from '../../../../utils/formatters';
import { RetailerOrderModel } from '../../../../data/order/model';
import useToken from '../../../../hooks/useToken';

interface PaymentProps {
  customerType: 'retailer' | 'distributor';
  data: RetailerOrderModel;
}

const StyledButton = styled(Button)({
  padding: '3%',
  justifyContent: 'flex-start',
  gap: '2rem',
  paddingLeft: '1rem',
});

const mapData = (
  data: RetailerOrderModel,
  customerType: 'retailer' | 'distributor'
) =>
  customerType === 'retailer'
    ? {
        approvalTS: data.approvalTS,
        name: data.storeName,
        EstimatedPayment: data.retailerEstimatedPaymentAmount,
        paymentStatus: data.retailerPaymentStatus,
        orderID: data.orderID,
        remainderCounter: data.retailerPaymentReminderCounter,
        orderStatus: data.statusName,
        customerNumber: data.customerNumber,
        orderAmount: data.orderAmount,
      }
    : {
        approvalTS: data.approvalTS,
        name: data.storeName,
        EstimatedPayment: data.distributorEstimatedPaymentAmount,
        paymentStatus: data.distributorPaymentStatus,
        orderID: data.orderID,
        remainderCounter: data.distributorPaymentReminderCounter,
        orderStatus: data.statusName,
        orderAmount: data.orderAmount,
        customerNumber: data.customerNumber,
      };
const Payment: React.FC<PaymentProps> = ({ customerType, data }) => {
  const [isHoverMoneyButton, setIsHoverMoneyButton] = useState(false);
  const [isHoverMessageButton, setIsHoverMessageButton] = useState(false);
  const { Company } = useToken();

  const navigate = useNavigate();
  const {
    paymentStatus,
    remainderCounter,
    approvalTS,
    orderID,
    name,
    EstimatedPayment,
    orderAmount,
    orderStatus,
    customerNumber,
  } = mapData(data, customerType);
  return (
    <Stack spacing={2}>
      {paymentStatus === 1 &&
        approvalTS &&
        orderStatus !== 'Canceled' &&
        (Company === 'RentCo' || Company === 'BTB') && (
          <>
            <StyledButton
              onMouseEnter={() => setIsHoverMoneyButton(!isHoverMoneyButton)}
              onMouseLeave={() => setIsHoverMoneyButton(!isHoverMoneyButton)}
              variant="outlined"
              color="info"
              sx={{
                '&:hover': {
                  backgroundColor: colors.green,
                },
              }}
              onClick={() =>
                navigate(`/orderPayment`, {
                  state: {
                    orderID,
                    amount: orderAmount,
                    customerType,
                    approvalTS,
                  },
                })
              }
            >
              <PaymentIcon
                fill={isHoverMoneyButton ? colors.white : colors.green}
              />
              {customerType === 'distributor'
                ? `PAID TO ${customerType.toUpperCase()}`
                : `PAID BY ${customerType.toUpperCase()}`}
            </StyledButton>
            {customerType === 'retailer' && (
              <StyledButton
                variant="outlined"
                color="info"
                onClick={() =>
                  navigate(`/Reminders/${orderID}`, {
                    state: {
                      customerType,
                      date: approvalTS,
                      orderID,
                      paymentAmount: EstimatedPayment,
                      orderStatus,
                      name,
                      // TODO:: Need to change
                      creditPeriod: 7,
                      customerNumber,
                    },
                  })
                }
                onMouseEnter={() =>
                  setIsHoverMessageButton(!isHoverMessageButton)
                }
                onMouseLeave={() =>
                  setIsHoverMessageButton(!isHoverMessageButton)
                }
                sx={{
                  '&:hover': {
                    backgroundColor: colors.red,
                  },
                }}
              >
                <RemainderIcon
                  fill={isHoverMessageButton ? colors.white : colors.red}
                />
                SEND REMINDER ({remainderCounter ?? 0})
              </StyledButton>
            )}
          </>
        )}
      {paymentStatus === 2 &&
        orderStatus !== 'Canceled' &&
        (Company === 'RentCo' || Company === 'BTB') && (
          <>
            <StyledButton
              onMouseEnter={() => setIsHoverMoneyButton(!isHoverMoneyButton)}
              onMouseLeave={() => setIsHoverMoneyButton(!isHoverMoneyButton)}
              variant="outlined"
              color="info"
              sx={{
                '&:hover': {
                  backgroundColor: colors.green,
                },
              }}
              onClick={() =>
                navigate(`/orderPayment`, {
                  state: {
                    orderID,
                    amount: EstimatedPayment,
                    customerType,
                    approvalTS,
                  },
                })
              }
            >
              <PaymentIcon
                fill={isHoverMoneyButton ? colors.white : colors.green}
              />
              PAID BY {customerType.toUpperCase()}
            </StyledButton>
            <Typography fontSize={16} color={colors.red}>
              Marked as paid by the retailer
            </Typography>
          </>
        )}
      {paymentStatus === 3 && (
        <Box>
          <Typography variant="body2" color={colors.darkGray}>
            Successfully paid {numberFormatter(EstimatedPayment)}{' '}
            {moment(approvalTS).format('DD/MM/YYYY')}
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default Payment;
