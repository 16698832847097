import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../assets/colors';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import { OrderStatus } from '../../../data/models/Status';
import { useOrderLine, useStatusUpdate } from '../../../data/order/hooks';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { numberFormatter } from '../../../utils/formatters';
import Title from '../../shared/Title';

const ApproveOrder: React.FC = () => {
  const location = useLocation();
  const { storeName, orderID, orderTotal } = location.state as {
    storeName: string;
    orderTotal: number;
    orderID: string;
  };
  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();

  const { data: products, isLoading } = useOrderLine(orderID);
  const { mutateAsync: updateOrderStatus, isLoading: isLoadingUpdateStatus } =
    useStatusUpdate();

  if (isLoadingUpdateStatus)
    return <LoaderSpinner isLoading={isLoadingUpdateStatus} />;

  return (
    <Box
      sx={
        isMobile
          ? { margin: '8%' }
          : { width: 'fit-content', margin: '4% auto' }
      }
    >
      <Title content="Approve Order" icon="approve" />
      <Stack>
        <Box
          sx={{
            alignSelf: 'start',
            display: 'flex',
            gap: '1.2rem',
            flexDirection: 'column',
          }}
        >
          <Typography
            variant="h5"
            sx={{ color: colors.blue, marginBottom: '1rem' }}
          >
            {storeName}
          </Typography>
          {isLoading ? (
            <Typography>Fetch order products ....</Typography>
          ) : (
            <Typography variant="subtitle2">{storeName} asks for : </Typography>
          )}

          {products?.map((product, index) => (
            <Box
              sx={{ display: 'grid', gap: '5px', width: 'fit-content' }}
              key={index}
            >
              <Typography
                sx={{
                  gridColumn: 1,
                  gridRow: 1,
                  color: colors.blue,
                  marginRight: '5px',
                }}
                variant="subtitle2"
              >
                {index + 1}{' '}
              </Typography>
              <Typography
                sx={{ gridColumn: 2, gridRow: 1, color: colors.darkGray }}
                variant="body1"
              >
                {product.productName}{' '}
              </Typography>
              <Typography
                sx={{ gridColumn: 2, gridRow: 2, color: colors.darkGray }}
                variant="body1"
              >
                {numberFormatter(product.distributionPrices)} X{' '}
                {product.quantity} ={' '}
                {numberFormatter(product.distributionPrices * product.quantity)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography variant="subtitle2" sx={{ marginTop: '1rem' }}>
          Total order is {numberFormatter(orderTotal)}{' '}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'start',
            flexDirection: 'column',
            width: isMobile ? '100%' : '30rem',
            marginTop: '5rem',
            gap: '1.2rem',
          }}
        >
          <Button
            variant="outlined"
            color="success"
            onClick={async () => {
              await updateOrderStatus({
                orderID,
                newStatus: OrderStatus.Approved,
              });
              navigate('/orders');
            }}
            sx={{ padding: '0.5rem', border: '2px solid' }}
          >
            Approve
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={() => navigate('/orders')}
            sx={{ padding: '0.5rem', border: '2px solid' }}
          >
            Back to all orders
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={async () => {
              await updateOrderStatus({
                orderID,
                newStatus: OrderStatus.Rejected,
              });
              navigate('/orders');
            }}
            sx={{
              color: colors.red,
              padding: '0.5rem',
              border: `2px solid ${colors.red}`,
            }}
          >
            Reject
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
export default ApproveOrder;
