import { Box, Typography } from '@mui/material';
import React from 'react';
import Title from './shared/Title';

const ErrorPage: React.FC = () => (
  <>
    <Box sx={{ width: 'fit-content', margin: '4% auto' }}>
      <Title content="Error" icon="error" />
      <Typography variant="body1">
        Seems like there is a problem with your network
      </Typography>
    </Box>
  </>
);

export default ErrorPage;
