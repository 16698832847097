import { Box, Button, keyframes, styled } from '@mui/material';
import React from 'react';

import { ReactComponent as ContactImg } from '../assets/svgs/contact.svg';

const wiggle = keyframes`
  0%, 7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%, 100% {
    transform: rotateZ(0);
  }
}`;

const ContactButton = styled(Button)(({ theme }) => ({
  padding: 0,
  borderRadius: '40px',
  transition: 'all 0.75s ease-in-out',
  animation: `${wiggle} 2s ease-in-out forwards`,
  ':hover': {
    backgroundColor: 'transparent',
  },
  [theme.breakpoints.down(590)]: {
    width: 70,
    height: 70,
  },
}));

const ContactUsLogo: React.FC = () => (
  <Box
    sx={{
      padding: 0,
      margin: 0,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      position: 'fixed',
      right: '5px',
      bottom: '5px',
    }}
  >
    <ContactButton href="/ContactUs">
      <ContactImg />
    </ContactButton>
  </Box>
);

export default ContactUsLogo;
