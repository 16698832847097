import { useMutation } from 'react-query';
import { useQuery } from '../utils/reactQuery';
import {
  getOrder,
  getOrderLines,
  sendRemainder,
  payOrder,
  updateStatus,
} from './dataService';
import { UpdateOrderRequest } from './model';

export const useGetRetailersOrder = () =>
  useQuery('retailerOrder', () => getOrder());

export const useOrderLine = (orderId: string) =>
  useQuery(['retailerOrder', orderId], () => getOrderLines(orderId));

export const useOrderUpdate = () =>
  useMutation((data: UpdateOrderRequest) => payOrder(data));

export const useRemainder = () =>
  useMutation(
    (data: { orderID?: string; customerType: 'retailer' | 'distributor' }) =>
      sendRemainder(data)
  );

export const useStatusUpdate = () =>
  useMutation((data: { orderID: string; newStatus: number }) =>
    updateStatus(data)
  );
