import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { moneyFormatter } from '../../../../utils/formatters';
import Title from '../../../shared/Title';

const CreditChangeMessage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const data = location.state as {
    storeName: string;
    creditAmount: number;
    creditPeriod: number;
  };
  return (
    <Stack sx={{ margin: '0 auto' }}>
      <Box
        sx={{
          margin: '4% auto',
          width: 'fit-content',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Title
          sx={{
            fontSize: '2.9rem',
            textAlign: 'start',
            lineHeight: '3.8rem',
          }}
          content="Change Retailer's Credit"
        />
        <Box sx={{ width: '70%' }}>
          <Typography
            sx={{ fontWeight: 500, fontSize: '20px', paddingBottom: '1.5rem' }}
            variant="subtitle2"
            color="error"
          >
            {' '}
            The credit was successfully changed.
          </Typography>
          <Typography
            sx={{ fontWeight: 500, fontSize: '20px', paddingBottom: '1.5rem' }}
            color="error"
          >
            {' '}
            {data.storeName} new credit amount is KES{' '}
            {moneyFormatter(data.creditAmount)}, and the credit period is{' '}
            {data.creditPeriod} days{' '}
          </Typography>
        </Box>

        <Box sx={{ margin: '0 auto', width: '70%' }}>
          <Button
            variant="outlined"
            onClick={() => navigate('/RetailersCredit')}
            color="info"
            sx={{
              width: '100%',
              marginTop: '2rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Back
          </Button>
        </Box>
      </Box>
    </Stack>
  );
};
export default CreditChangeMessage;
