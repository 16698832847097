import React, { useMemo } from 'react';
import { Box } from '@mui/material';
import StatusBadge from '../../UIComponents/StatusBadge';
import { Column, CustomTable, Row } from '../../UIComponents/Table';
import Payment from './Payment';
import Action from './Action';
import { useGetRetailersOrder } from '../../../../data/order/hooks';
import { RetailerOrderModel } from '../../../../data/order/model';
import { numberFormatter } from '../../../../utils/formatters';
import useToken from '../../../../hooks/useToken';
import Title from '../../../shared/Title';
import RetailerInfo from '../Shared/RetailerInfo';
import { LoaderSpinner } from '../../../../contexts/spinnerContext';
import { PaymentStatus } from '../../../../data/models/Status';

const statusMap = (status: string) => {
  switch (status) {
    case 'Approved':
      return 'success';
    case 'Canceled':
      return 'error';
    case 'Padding':
      return 'info';
    case 'Rejected':
      return 'error';
    default:
      return 'info';
  }
};

const createTableData = (
  data: RetailerOrderModel[]
): { rows: Row<RetailerOrderModel>[][]; columns: Column[] } => {
  const columns: Column[] = [
    { name: 'Retailer Information', style: { width: '15%' } },
    { name: 'Distributor', style: { width: '7%' } },
    { name: 'Customer ID', style: { width: '7%' } },
    { name: 'Order Amount', style: { width: '10%' } },
    { name: 'Status', style: { width: '7%' } },
    { name: 'Distributor Payment', style: { width: '13%' } },
    { name: 'Retailer Payment', style: { width: '13%' } },
    { name: 'Actions', style: { width: '13%' } },
  ];

  let row: Row<RetailerOrderModel>[] = [];
  const rows: Row<RetailerOrderModel>[][] = [];
  data.forEach((element) => {
    row.push({
      accessor: 'storeName',
      data: element.storeName,
      render: () => (
        <RetailerInfo orderId={element.orderID} storeName={element.storeName} />
      ),
    });
    row.push({ accessor: 'distributorName', data: element.distributorName });
    row.push({ accessor: 'customerNumber', data: element.customerNumber });
    row.push({
      accessor: 'orderAmount',
      data: element.orderAmount,
      render: () => <span>{numberFormatter(element.orderAmount)} </span>,
    });
    row.push({
      accessor: 'statusName',
      data: element.statusID,
      render: () => (
        <StatusBadge
          text={element.statusName}
          color={statusMap(element.statusName)}
        ></StatusBadge>
      ),
    });
    row.push({
      accessor: 'distributorEstimatedPaymentTS',
      data: element.statusName,
      render: () => (
        <Payment customerType="distributor" data={element}></Payment>
      ),
    });
    row.push({
      accessor: 'retailerEstimatedPaymentTS',
      data: element.statusName,
      render: () => <Payment customerType="retailer" data={element}></Payment>,
    });
    row.push({
      accessor: 'retailerEstimatedPaymentTS',
      data: element.statusName,
      render: () => (
        <Action
          data={element}
          orderStatus={element.statusID}
          isPaid={
            element.distributorPaymentStatus !== PaymentStatus.Pending ||
            element.retailerPaymentStatus !== PaymentStatus.Pending
          }
        />
      ),
    });
    rows.push(row);
    row = [];
  });

  return { rows, columns };
};

export const RetailerOrder: React.FC = () => {
  const { isLoading, data } = useGetRetailersOrder();
  useToken();

  const result = useMemo<
    | {
        rows: Row<RetailerOrderModel>[][];
        columns: Column[];
      }
    | undefined
  >(() => {
    if (data) return createTableData(data);
    return undefined;
  }, [data]);

  if (isLoading) return <LoaderSpinner isLoading />;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          margin: '4% 0 0 0',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Title content="Your Orders" />
      </Box>
      {!isLoading && result && (
        <>
          <CustomTable {...result}></CustomTable>
        </>
      )}
    </>
  );
};

export default RetailerOrder;
