import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import { Fade, Stack, Box, styled } from '@mui/material';
import { createPortal } from 'react-dom';
import loadingGif from '../assets/gifs/loading.gif';

const StyledImg = styled('img')(({ theme }) => ({
  width: '580px',
  [theme.breakpoints.down('sm')]: {
    width: '350px',
  },
}));

type SpinnerType = {
  isLoading: boolean;
};

interface ContextType {
  spinnerState: SpinnerType;
  setSpinner: (state: SpinnerType) => void;
}

const initialState: ContextType = {
  spinnerState: { isLoading: false },
  setSpinner: () => undefined,
};

// eslint-disable-next-line react/prop-types
export const LoaderSpinner: React.FC<SpinnerType> = React.memo(
  // eslint-disable-next-line react/prop-types
  ({ isLoading }) => (
    <Fade in={isLoading} unmountOnExit timeout={100}>
      <Box
        sx={{
          position: 'fixed',
          display: 'grid',
          placeItems: 'center',
          top: 0,
          left: 0,
          color: 'primary.main',
          backgroundColor: 'rgb(199 199 199 / 55%)',
          width: '100%',
          height: '100%',
          zIndex: 1500,
        }}
      >
        <Stack alignItems="center">
          <StyledImg src={loadingGif} alt="loading..." />
        </Stack>
      </Box>
    </Fade>
  )
);

LoaderSpinner.displayName = 'SpinnerModel';

const spinnerContext = createContext<ContextType>(initialState);

export const useSpinnerContext = () => useContext(spinnerContext);
interface Props {
  children: React.ReactNode;
}
export const SpinnerProvider: React.FC<Props> = ({ children, ...props }) => {
  const [isLoading, setIsLoading] = useState(
    initialState.spinnerState.isLoading
  );
  const [counter, setCounter] = useState(0);

  const callSetSpinner = useCallback(
    (state: SpinnerType) => {
      setCounter((pre) => Math.max(0, state.isLoading ? pre + 1 : pre - 1));
    },
    [setIsLoading]
  );
  useEffect(() => {
    if (counter > 0) setIsLoading(true);
    else setIsLoading(false);
  }, [setIsLoading, counter]);

  return (
    <spinnerContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{ spinnerState: { isLoading }, setSpinner: callSetSpinner }}
      {...props}
    >
      {children}
      {createPortal(<LoaderSpinner {...{ isLoading }} />, document.body)}
    </spinnerContext.Provider>
  );
};
