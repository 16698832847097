import { Box, Button, Stack, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../assets/colors';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import { OrderStatus } from '../../../data/models/Status';
import { useStatusUpdate } from '../../../data/order/hooks';
import Title from '../../shared/Title';

const CancelOrder: React.FC = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { mutateAsync: updateOrderStatus, isLoading } = useStatusUpdate();
  const { orderID, storeName } = state as {
    orderID: string;
    storeName: string;
  };
  if (isLoading) return <LoaderSpinner isLoading />;
  return (
    <Box sx={{ width: '30rem', margin: '4% auto' }}>
      <Title content="Cancel Retailer Order" icon="remainder" />
      <Stack sx={{ gap: '2rem' }}>
        <Typography variant="h5" sx={{ color: colors.blue }}>
          {storeName}
        </Typography>
        <Typography>The order will be canceled.</Typography>
        <Stack sx={{ gap: '1rem' }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ border: `2px solid ${colors.gray}`, padding: '0.5rem' }}
            onClick={() => navigate('/orders')}
          >
            Back to all orders
          </Button>
          <Button
            variant="outlined"
            color="error"
            sx={{
              border: `2px solid ${colors.red}`,
              color: colors.red,
              padding: '0.5rem',
            }}
            onClick={async () => {
              await updateOrderStatus({
                orderID,
                newStatus: OrderStatus.Canceled,
              });
              navigate('/orders');
            }}
          >
            Cancel order
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};

export default CancelOrder;
