import { ValidateResult } from 'react-hook-form';

const emailPattern =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const kenyaCellPhonePattern = {
//   safaricom:
//     /^(\+){0,1}(254){0,1}(70|71|72|79)(\d{7})|(254){0,3}(740|741|742|743|745|746|748|757|758|759|768|769|110|111)(\d{6})$/,
//   airtel:
//     /^(\+){0,1}(254){0,1}(10)(\d{7})|(254){0,3}(780|781|782|783|784|785|786|787|788|789|730|731|732|733|734|735|736|737|738|739|750|751|752|753|754|755|756|762)(\d{6})$/,
//   orange: /^(?:254|\+254|0)?(77[0-6][0-9]{6})$/,
//   equitel: /^(?:254|\+254|0)?(76[34][0-9]{6})$/,
// };

export const emailValidator = (input: string): ValidateResult =>
  emailPattern.test(input) ? true : 'Invalid email';

// export const kenyaCellphoneValidator = (input: string): ValidateResult =>
//   kenyaCellPhonePattern.safaricom.test(input) ||
//   kenyaCellPhonePattern.airtel.test(input) ||
//   kenyaCellPhonePattern.orange.test(input) ||
//   kenyaCellPhonePattern.equitel.test(input)
//     ? true
//     : 'Invalid cellphone';

export const kenyaCellphoneValidator = (input: string): ValidateResult =>
  input.length > 5 ? true : 'Invalid cellphone';
