import React, { useMemo, useState } from 'react';
import { Box, Button, InputAdornment, TextField } from '@mui/material';
import moment from 'moment';
import { Column, CustomTable, Row } from '../UIComponents/Table';
import useToken from '../../../hooks/useToken';
import Title from '../../shared/Title';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import { ViewProducts } from '../../../data/products/model';
import { useGetProducts, usePriceUpdate } from '../../../data/products/hooks';
import { moneyFormatter } from '../../../utils/formatters';
import colors from '../../../assets/colors';

let valueToUpdate: {
  productID: string;
  corDisConnectionID: string;
  distributionPriceA?: number;
  distributionPriceB?: number;
}[] = [];
const handlePriceChange = (
  productID: string,
  corDisConnectionID: string,
  priceA?: number,
  priceB?: number
) => {
  const value = valueToUpdate.find((x) => x.productID === productID);
  if (value) {
    if (priceA) value.distributionPriceA = priceA;
    else value.distributionPriceB = priceB;
  } else
    valueToUpdate.push({
      productID,
      corDisConnectionID,
      distributionPriceA: priceA,
      distributionPriceB: priceB,
    });
};

const sortData = (data: ViewProducts[]) => {
  const sortedData = [];
  for (let index = 0; index < data.length - 1; index += 2) {
    sortedData.push({
      internationalBarcode: data[index].internationalBarcode,
      productBrand: data[index].productBrand,
      productName: data[index].productName,
      distributionPriceAID: data[index].distributionPriceID,
      distributionPriceBID: data[index + 1].distributionPriceID,
      priceListNumber: data[index].priceListNumber,
      distributionPriceA: data[index].distributionPrice,
      distributionPriceB: data[index + 1].distributionPrice,
      corporateDistributorConnectionID:
        data[index].corporateDistributorConnectionID,
      updateTS: data[index].updateTS,
    });
  }

  return sortedData;
};

const createTableData = (
  data: ViewProducts[],
  onChangeDispatcher: React.Dispatch<React.SetStateAction<boolean>>
): { rows: Row<ViewProducts>[][]; columns: Column[] } => {
  const columns: Column[] = [
    { name: 'International Barcode', style: { width: '15%' } },
    { name: 'Product Brand', style: { width: '10%' } },
    { name: 'Product Name', style: { width: '10%' } },
    { name: 'Price List A', style: { width: '13%' } },
    { name: 'Price List B', style: { width: '13%' } },
    { name: 'Last Update', style: { width: '10%' } },
  ];
  const sortedData = sortData(data);
  let row: Row<ViewProducts>[] = [];
  const rows: Row<ViewProducts>[][] = [];
  sortedData.forEach((element) => {
    row.push({
      accessor: 'internationalBarcode',
      data: element.internationalBarcode,
    });
    row.push({ accessor: 'productBrand', data: element.productBrand });
    row.push({ accessor: 'productName', data: element.productName });
    row.push({
      accessor: 'distributionPrice',
      data: element.distributionPriceA,
      render: () => (
        <TextField
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ margin: 0, marginRight: '0.5rem', padding: 0 }}
                position="end"
              >
                KES
              </InputAdornment>
            ),
          }}
          defaultValue={moneyFormatter(element.distributionPriceA)}
          onBlur={(e) => {
            e.target.style.color =
              Number(e.target.value.replaceAll(',', '')) !==
              element.distributionPriceA
                ? colors.red
                : colors.black;

            handlePriceChange(
              element.distributionPriceAID,
              element.corporateDistributorConnectionID,
              Number(e.target.value.replaceAll(',', '')),
              undefined
            );
            e.target.value =
              moneyFormatter(Number(e.target.value.replaceAll(',', ''))) ||
              e.target.value;
            onChangeDispatcher(true);
          }}
        />
      ),
    });
    row.push({
      accessor: 'distributionPrice',
      data: element.distributionPriceB,
      render: () => (
        <TextField
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment
                sx={{ margin: 0, marginRight: '0.5rem', padding: 0 }}
                position="end"
              >
                KES
              </InputAdornment>
            ),
          }}
          defaultValue={moneyFormatter(element.distributionPriceB)}
          onBlur={(e) => {
            e.target.style.color =
              Number(e.target.value.replaceAll(',', '')) !==
              element.distributionPriceB
                ? colors.red
                : colors.black;
            handlePriceChange(
              element.distributionPriceBID,
              element.corporateDistributorConnectionID,
              undefined,
              Number(e.target.value.replaceAll(',', ''))
            );
            e.target.value =
              moneyFormatter(Number(e.target.value.replaceAll(',', ''))) ||
              e.target.value;
            onChangeDispatcher(true);
          }}
        />
      ),
    });
    row.push({
      accessor: 'updateTS',
      data: moment(element.updateTS).format('DD/MM/YYYY'),
    });
    rows.push(row);
    row = [];
  });

  return { rows, columns };
};

export const Products: React.FC = () => {
  const { Company } = useToken();
  const { isLoading, data } = useGetProducts();
  const [tableChange, setTableChange] = useState(false);
  const { mutateAsync: updatePrice, isLoading: isUpdatePriceLoading } =
    usePriceUpdate();

  useToken();
  const result = useMemo<
    | {
        rows: Row<ViewProducts>[][];
        columns: Column[];
      }
    | undefined
  >(() => {
    if (data) return createTableData(data, setTableChange);
    return undefined;
  }, [data]);

  if (Company === 'BAT') window.location.href = '/login';
  if (isLoading || isUpdatePriceLoading) return <LoaderSpinner isLoading />;

  return (
    <Box sx={{ position: 'fixed', top: '8%', width: '93%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Title
          sx={{
            fontSize: '2.9rem',
            textAlign: 'start',
            lineHeight: '3.8rem',
          }}
          content="Products' Price List"
        />
        <Box
          sx={{
            position: 'fixed',
            right: '5rem',
            top: '15rem',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            variant="outlined"
            color={!tableChange ? 'success' : 'error'}
            disabled={!tableChange}
            onClick={async () => {
              await updatePrice(valueToUpdate);
              valueToUpdate = [];
              window.location.reload();
            }}
          >
            Save All Changes
          </Button>
        </Box>
      </Box>
      {!isLoading && result && (
        <>
          <CustomTable
            {...result}
            pagination={false}
            sx={{ maxHeight: '60vh'}}
          ></CustomTable>
        </>
      )}
    </Box>
  );
};

export default Products;
