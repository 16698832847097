import jwtDecode from 'jwt-decode';

const useToken = (): {
  uuid?: string;
  email?: string;
  name?: string;
  isLogin: boolean;
  Company?: string;
} => {
  const token = localStorage.getItem('token');
  if (!token) {
    return { isLogin: false };
  }
  const { SegmentUUID, email, aud, Company } = jwtDecode<Token>(token);
  return { uuid: SegmentUUID, email, name: aud, isLogin: true, Company };
};

export default useToken;

interface Token {
  ID?: string;
  SegmentType?: string;
  SegmentUUID?: string;
  aud?: string;
  email?: string;
  role?: string;
  Company?: string;
}
