import { Box, Button, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import colors from '../../../assets/colors';
import { useOrderLine } from '../../../data/order/hooks';
import { numberFormatter } from '../../../utils/formatters';
import Title from '../../shared/Title';

const statusMap = (status: string) => {
  switch (status) {
    case 'Approved':
    case 'Paid':
      return colors.green;
    case 'Canceled':
      return colors.red;
    case 'Padding':
      return colors.blue;
    default:
      return colors.blue;
  }
};

const StyledSpan = styled('span')({
  color: colors.black,
});
const ViewOrder: React.FC = () => {
  const location = useLocation();
  const { storeName, customerNumber, orderStatus, orderID } =
    location.state as {
      storeName: string;
      customerNumber: number;
      orderStatus: string;
      orderID: string;
    };
  const navigate = useNavigate();
  const { data: products, isLoading } = useOrderLine(orderID);
  let amount = 0;
  products?.forEach((p) => {
    amount += p.distributionPrices * p.quantity;
    return products;
  });
  return (
    <Box sx={{ width: 'fit-content', margin: '4% auto' }}>
      <Title content="View Order" icon="list" />
      <Stack>
        <Box
          sx={{
            alignSelf: 'start',
            display: 'flex',
            gap: '1.2rem',
            flexDirection: 'column',
          }}
        >
          <Typography variant="h5" sx={{ color: colors.blue }}>
            {storeName}
          </Typography>
          <Typography variant="subtitle2">
            Customer Number : {customerNumber}
          </Typography>
          <Typography variant="subtitle2">
            Order Status :{' '}
            <StyledSpan sx={{ color: statusMap(orderStatus) }}>
              {orderStatus === 'pending' ? 'Pending' : orderStatus}
            </StyledSpan>
          </Typography>
          {isLoading ? (
            <Typography>Loading Order Products ....</Typography>
          ) : (
            <Typography variant="subtitle2">{storeName} Ordered : </Typography>
          )}

          {products?.map((product, index) => (
            <Box
              sx={{ display: 'grid', gap: '5px', width: 'fit-content' }}
              key={index}
            >
              <Typography
                sx={{
                  gridColumn: 1,
                  gridRow: 1,
                  color: colors.blue,
                  marginRight: '5px',
                }}
                variant="subtitle2"
              >
                {index + 1}{' '}
              </Typography>
              <Typography
                sx={{ gridColumn: 2, gridRow: 1, color: colors.darkGray }}
                variant="body1"
              >
                {product.productName}{' '}
              </Typography>
              <Typography
                sx={{ gridColumn: 2, gridRow: 2, color: colors.darkGray }}
                variant="body1"
              >
                {numberFormatter(product.distributionPrices)} X{' '}
                {product.quantity} ={' '}
                {numberFormatter(product.distributionPrices * product.quantity)}
              </Typography>
            </Box>
          ))}
        </Box>
        <Typography variant="subtitle2" sx={{ marginTop: '1.2rem' }}>
          Order Total : {numberFormatter(amount)}{' '}
        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignSelf: 'start',
            flexDirection: 'column',
            width: '30rem',
            marginTop: '5rem',
          }}
        >
          <Button
            variant="outlined"
            color="info"
            onClick={() => navigate('/orders')}
            sx={{
              padding: '0.5rem',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Back to all orders
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};
export default ViewOrder;
