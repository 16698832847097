import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';

const baseConfig: AxiosRequestConfig = {
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  headers: {
    'Content-type': 'application/json',
  },
};
export type PostResponse = AxiosResponse<{ id: string }>;
const api = axios.create(baseConfig);
api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token');
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
    // eslint-disable-next-line no-param-reassign
    config.baseURL = process.env.REACT_APP_API_BASE_PATH;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
api.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  'token'
)}`;

export default api;
