import api from '../axios/api';

export interface ContactUsRequest {
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
}

const BASE_URL = 'ContactUs';
export async function insertContactUsRequest(
  data: ContactUsRequest
): Promise<string> {
  const res = await api.post(`${BASE_URL}`, data);
  return res.data;
}
