import {
  Box,
  Button,
  Checkbox,
  FormHelperText,
  FormLabel,
  Link,
  styled,
  TextField,
} from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import colors from '../../../assets/colors';
import { LoaderSpinner } from '../../../contexts/spinnerContext';
import { useContactUsInsertion } from '../../../data/contactUs/hooks';
import useBreakpoint from '../../../hooks/useBreakpoint';
import {
  emailValidator,
  kenyaCellphoneValidator,
} from '../../../utils/validators';
import Title, { StyledFormDescription } from '../../shared/Title';

interface ContactUsForm {
  firstName: string;
  lastName: string;
  email: string;
  cellphone: string;
  acceptTermOfUse: boolean;
}

const defaultValues: ContactUsForm = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  acceptTermOfUse: false,
};

const FormContainer = styled(Box)(() => ({
  display: 'grid',
  justifyContent: 'start',
  justifyItems: 'center',
  alignItems: 'start',
  gridTemplateColumns: 'repeat(2, 1fr)',
  columnGap: '4rem',
  rowGap: '2rem',
}));

const ContactUs: React.FC = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ContactUsForm>({
    defaultValues,
    mode: 'onBlur',
  });

  const navigate = useNavigate();
  const { isMobile } = useBreakpoint();
  const { mutateAsync: insertContactUsRequest, isLoading } =
    useContactUsInsertion();
  const submit = async (data: ContactUsForm) => {
    const { firstName, lastName, email, cellphone } = data;
    await insertContactUsRequest({
      firstName,
      lastName,
      email,
      cellphone,
    });
    navigate(-1);
  };

  if (isLoading) return <LoaderSpinner isLoading={isLoading} />;

  return (
    <Box
      sx={
        isMobile
          ? { margin: '8%' }
          : { width: 'fit-content', margin: '4% auto' }
      }
    >
      <Title content="Contact Us" icon="contactUs" />

      <form onSubmit={handleSubmit((data) => submit(data))}>
        <Box sx={{ width: 'fit-content', margin: '0 auto' }}>
          <StyledFormDescription variant="subtitle1">
            Please fill the form and we will be you shortly
          </StyledFormDescription>
          <FormContainer
            sx={
              isMobile
                ? {
                    gridTemplateColumns: 'repeat(1, 1fr)',
                    justifyItems: 'stretch',
                  }
                : {}
            }
          >
            <Controller
              name="firstName"
              control={control}
              rules={{
                required: { value: true, message: 'Required field' },
                maxLength: {
                  value: 64,
                  message: 'First name should not exceed 32 characters',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  sx={{ minWidth: '200px' }}
                  error={!!error?.message}
                  InputLabelProps={{ sx: { color: colors.black } }}
                  label="First Name *"
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              rules={{
                required: { value: true, message: 'Required field' },
                maxLength: {
                  value: 64,
                  message: 'Last name should not exceed 32 characters',
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error?.message}
                  label="Last Name *"
                  InputLabelProps={{ sx: { color: colors.black } }}
                  sx={{ minWidth: '200px' }}
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="email"
              control={control}
              rules={{
                required: { value: true, message: 'Required field' },
                validate: {
                  isEmail: (v) => emailValidator(v),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error?.message}
                  label="Email *"
                  InputLabelProps={{ sx: { color: colors.black } }}
                  sx={{ minWidth: '200px' }}
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />
            <Controller
              name="cellphone"
              control={control}
              rules={{
                required: { value: true, message: 'Required field' },
                validate: {
                  isCellphone: (v) => kenyaCellphoneValidator(v),
                },
              }}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  error={!!error?.message}
                  InputLabelProps={{ sx: { color: colors.black } }}
                  label="Cellphone *"
                  sx={{ minWidth: '200px' }}
                  variant="standard"
                  helperText={error?.message}
                />
              )}
            />
          </FormContainer>
          <Box sx={{ margin: '2rem 0', marginLeft: '-2px' }}>
            <Controller
              control={control}
              rules={{ required: { value: true, message: 'Required field' } }}
              name="acceptTermOfUse"
              defaultValue={false}
              render={({ field }) => (
                <Checkbox
                  sx={{
                    margin: 0,
                    padding: 0,
                    marginRight: '0.5rem',
                    '&.Mui-checked': {
                      color: colors.green,
                    },
                  }}
                  {...field}
                />
              )}
            />
            <FormLabel>
              I agree the{' '}
              <Link href="/PrivacyTerms">Privacy & Terms and Conditions</Link>
            </FormLabel>
            {errors.acceptTermOfUse && (
              <FormHelperText sx={{ color: colors.red }}>
                Required field
              </FormHelperText>
            )}
          </Box>
          <Button
            type="submit"
            variant="outlined"
            sx={{
              width: '100%',
              marginTop: '1rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Send
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ContactUs;
