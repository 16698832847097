import { Box, Link, Modal, Stack, styled } from '@mui/material';
import React from 'react';
import colors from '../../../assets/colors';
import useBreakpoint from '../../../hooks/useBreakpoint';
import { ReactComponent as HamburgerIcon } from '../../../assets/svgs/hamburger.svg';
import { ReactComponent as ExitIcon } from '../../../assets/svgs/exit.svg';

interface NavBarProps {
  menuItems: { content: string; link: string }[];
}

const ButtonWrapper = styled('button')({
  background: colors.white,
  border: 0,
  right: 0,
});

const Container = styled('div')({});
const NavBar: React.FC<NavBarProps> = ({ menuItems }) => {
  const { isMobile } = useBreakpoint();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = () => setAnchorEl(null);
  return isMobile ? (
    <>
      <ButtonWrapper
        sx={{ position: 'fixed', top: '1.5rem', right: '1.5rem' }}
        onClick={handleClick}
      >
        <HamburgerIcon />
      </ButtonWrapper>

      <Modal open={open} onClose={handleMenuItemClick}>
        <Box
          sx={{
            backgroundColor: colors.white,
            margin: '20% 2% 0 2%',
            padding: '1rem 2rem 2rem 3rem',
            boxShadow: '0px 2px 5px 0px #9a9a9a',
          }}
        >
          <Box>
            <Container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <ExitIcon onClick={() => setAnchorEl(null)} />
            </Container>
            <Stack direction="column" gap={3}>
              {menuItems.map((m, index) => (
                <Link
                  sx={{ color: colors.black, textDecoration: 'none' }}
                  key={index}
                  href={m.link}
                >
                  {m.content}
                </Link>
              ))}
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  ) : (
    <>
      <Stack sx={{ flexDirection: 'row', gap: '2rem' }}>
        {menuItems.map((m, index) => (
          <Link
            key={index}
            sx={{
              color:
                window.location.href
                  .toLowerCase()
                  .indexOf(m.link.toLowerCase()) === -1
                  ? colors.black
                  : colors.blue,
              textDecoration: 'none',
            }}
            href={m.link}
            underline="hover"
          >
            {m.content}
          </Link>
        ))}
      </Stack>
    </>
  );
};

export default NavBar;
