import { Box, Button, styled } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import Title from '../../shared/Title';

import { ReactComponent as LendingPage } from '../../../assets/svgs/lendingPage.svg';
import useBreakpoint from '../../../hooks/useBreakpoint';

const StyledTable = styled('div')(() => ({}));

const Homepage: React.FC = () => {
  const { isMobile } = useBreakpoint();

  return (
    <Box
      sx={
        isMobile
          ? { margin: '8% 6%' }
          : { width: 'fit-content', margin: '4% auto', maxWidth: '40rem' }
      }
    >
      <Title content="Everybody Wins" />
      <StyledTable sx={isMobile ? {} : { width: '30rem' }}>
        <Box sx={isMobile ? {} : {}}>
          <LendingPage />
          <Button
            variant="outlined"
            href="/ContactUs"
            sx={{
              width: '100%',
              marginTop: '5rem',
              padding: '2%',
              border: '2px solid',
              '&:hover': {
                border: '2px solid',
              },
            }}
          >
            Click now to learn more
          </Button>
        </Box>
      </StyledTable>

      {!isMobile && (
        <Box
          sx={{
            position: 'fixed',
            bottom: '8px',
            left: '5px',
            display: 'flex',
            gap: '10px',
          }}
        >
          <Link to={'/CookiesPolicy'}>Cookies policy</Link>
          <Link to={'/PrivacyTerms'}>Privacy terms</Link>
        </Box>
      )}
    </Box>
  );
};

export default Homepage;
