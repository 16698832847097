import React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import { ProtectedRoute } from './auth/ProtectedRoute';
import ErrorPage from './components/ErrorPage';
import ApproveOrder from './components/pages/ApproveOrder/ApproveOrder';
import CancelOrder from './components/pages/CancelOrder/CancelOrder';
import ContactUs from './components/pages/ContactUs/ContactUs';
import CookiesPolicy from './components/pages/CookiesPolicy/CookiesPolicy';
import Homepage from './components/pages/Homepage/Homepage';
import Login from './components/pages/Login/Login';
import OrderPayment from './components/pages/OrderPayment/OrderPayment';
import PrivacyAndTerms from './components/pages/PrivacyAndTerms/PrivacyAndTerms';
import Products from './components/pages/Products/Products';
import Remainder from './components/pages/Remainder/Remainder';
import CreditChangeMessage from './components/pages/Retailers/RetailerCredit/CreditChangeMessgae';
import RetailerCredit from './components/pages/Retailers/RetailerCredit/RetailerCredit';
import UpdateCredit from './components/pages/Retailers/RetailerCredit/UpdateCredit';
import { RetailerOrder } from './components/pages/Retailers/RetailerOrder/RetailerOrder';
import ViewOrder from './components/pages/ViewOrder/ViewOrder';

export const router = createBrowserRouter([
  {
    path: '/Login',
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/Orders',
    element: <RetailerOrder />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/Reminders/:orderID',
    element: (
      <ProtectedRoute>
        <Remainder />
      </ProtectedRoute>
    ),
  },
  {
    path: '/ContactUs',
    element: <ContactUs />,
  },
  {
    path: '/OrderPayment',
    element: (
      <ProtectedRoute>
        <OrderPayment />
      </ProtectedRoute>
    ),
  },
  {
    path: '/OrderDetails',
    element: (
      <ProtectedRoute>
        <ViewOrder />
      </ProtectedRoute>
    ),
  },
  {
    path: '/PrivacyTerms',
    element: <PrivacyAndTerms />,
  },
  {
    path: '/CookiesPolicy',
    element: <CookiesPolicy />,
  },
  {
    path: '/ApproveOrder',
    element: (
      <ProtectedRoute>
        <ApproveOrder />
      </ProtectedRoute>
    ),
  },
  {
    path: '/CancelOrder',
    element: (
      <ProtectedRoute>
        <CancelOrder />
      </ProtectedRoute>
    ),
  },
  {
    path: '/Products',
    element: (
      <ProtectedRoute>
        <Products />
      </ProtectedRoute>
    ),
  },
  {
    path: '/RetailersCredit',
    element: (
      <ProtectedRoute>
        <RetailerCredit />
      </ProtectedRoute>
    ),
  },
  {
    path: '/UpdateCredit',
    element: (
      <ProtectedRoute>
        <UpdateCredit />
      </ProtectedRoute>
    ),
  },
  {
    path: '/CreditChangeMessage',
    element: (
      <ProtectedRoute>
        <CreditChangeMessage />
      </ProtectedRoute>
    ),
  },
  {
    path: '',
    element: <Homepage />,
    errorElement: <ErrorPage />,
  },
]);
